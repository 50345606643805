html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
body.hidden {
  overflow: hidden;
}

html,
body,
head,
#root {
  padding: 0;
  margin: 0;
  min-height: 100dvh;
}
#root {
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
}

$fontFamilyMariupol: 'Mariupol', sans-serif;
$fontFamilyInter: 'Inter', sans-serif;
$fontFamilyRoboto: 'Roboto', sans-serif;

$white: #ffffff;

$darkest100: #0b1922;
$darkest80: #3c474e;
$darkest60: #6d757a;
$darkest40: #9da3a7;
$darkest20: #ced1d3;
$darkest10: #e7e8e9;
$darkest5: #f3f3f4;

$primary100: #f59d33;
$primary60: #f9c485;
$primary40: #fbd8ad;
$primary20: #fdebd6;
$primary15: #fdf0e0;
$primary10: #fef5eb;

$secondaryBlue100: #22dde9;
$secondaryPink100: #ff3950;
$secondaryPurple100: #9912f5;

$error-light: #ef5350;
$error-dark: #c62828;
$error-main: #d32f2f;

$border-radius-sm: 6px;
$border-radius-md: 12px;
$border-radius-lg: 16px;

$shadow-sm: 0px 4px 8px rgba(0, 0, 0, 0.08);
$shadow-md: 0px 8px 16px rgba(0, 0, 0, 0.08);
$shadow-lg: 0px 16px 24px rgba(0, 0, 0, 0.08);

@mixin h1 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 109%;
}
@mixin h2 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116%;
}
@mixin h3 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
}
@mixin p1 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
}
@mixin p2 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.5%;
}
@mixin p3 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 116%;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}

.p3 {
  @include p3;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary20;
    border-radius: 90px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($primary20, 10%);
  }

  scrollbar-width: thin;
  scrollbar-color: $primary20 transparent;
}

/* prettier-ignore */
$breakpoints: (
  xs: 0,        // 0px
  sm: 36rem,    // 576px
  md: 48rem,    // 768px
  lg: 62rem,    // 992px
  xl: 75rem,    // 1200px
  xxl: 87.5rem, // 1400px
);

@mixin respond-to($breakpoint) {
  @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}
