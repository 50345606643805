@import '../../../../index.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  font-family: $fontFamilyInter;
  max-width: 600px;
}
.gradesList {
  display: inline-flex;
  width: fit-content;
  padding: 8px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  border-radius: 10px;
  background: white;

  li::marker {
    color: $primary100;
  }
  p {
    font-weight: 500;
  }
}

.bold {
  font-weight: 700;
  color: $darkest100;
  line-height: 150%;
}

.mainSubject {
  margin: 6px 0 12px;
}
