@import '../../index.scss';

.container {
  font-family: $fontFamilyMariupol;
  max-width: 1350px;
  margin: 24px auto;
  padding: 0 12px;
  width: 100%;
}

.containerRight {
  padding-right: 12px;
  margin-right: 12px;
}

.title {
  font-family: $fontFamilyMariupol;
  font-size: 40px !important;
  max-width: 570px;
  line-height: 109%;
}
.subtitle {
  font-family: $fontFamilyMariupol;
  font-size: 30px !important;
  line-height: 100%;
}

.published {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: $darkest40;
  margin-bottom: 12px;
}

.textContainer {
  font-family: $fontFamilyInter;
  max-width: 732px;
  margin-bottom: 24px;
  margin: 0 auto;
  p {
    font-weight: 500;
  }
}

.mon {
  margin-top: 4px;
  margin-bottom: 24px;
  a {
    color: $primary100;
    font-weight: 700;
    line-height: 150%;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}

.list {
  a {
    font-weight: 500;
    line-height: 150%;
    color: $darkest100;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .sublist {
    margin: 8px 0;
    padding-left: 16px;
  }
}

.tableContainer {
  max-width: 1440px;
  margin: 0 auto;
  padding: 12px;
}

.adviceItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $darkest100;
  }
}

.checkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: rgb(11, 167, 11);
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
  font-size: 14px;
}

.content {
  flex: 1;
}

.link {
  color: #f5a623;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.exampleList {
  margin-top: 8px;
  margin-left: 4px;

  p {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
