@import '../../../../index.scss';

.card {
  display: flex;
  flex-direction: column;
  font-family: $fontFamilyInter;
  background-color: $primary15;
  padding: 10px 7px;
  gap: 9px;
  border-radius: 8px;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.title {
  font-size: 14px;
  line-height: 19.6px;
  color: $darkest100;
}

.offersWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.propertyWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  line-height: 14px;
  border-radius: 5px;
  padding: 4px;
  background-color: white;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;

  a:nth-of-type(2) {
    flex: 1;
  }
}
