@import '../../../../../index.scss';

.tabsContainer {
  display: flex;
  overflow-x: auto;
  flex-wrap: wrap;
  width: fit-content;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}

.tabButton {
  padding: 12px 20px;
  font-family: $fontFamilyInter;
  font-weight: 400;
  font-size: 16px;
  color: $darkest80;
  background-color: $primary15;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  transition: color 0.2s ease;

  &:last-child {
    &::after {
      content: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 50%;
    width: 1px;
    background-color: $primary60;
    right: -1px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &:hover {
    color: $primary100;
  }

  &.active {
    color: white;
    background-color: $primary100;
    font-weight: 500;
    z-index: 3;
  }
}

@media (max-width: 768px) {
  .tabButton {
    padding: 10px 16px;
    font-size: 14px;
  }
}
