@import '../../../../../index.scss';

.searchContainer {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.searchInput {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px 12px 40px;
  border: 1px solid $darkest20;
  border-radius: 8px;
  font-family: $fontFamilyInter;
  font-size: 14px;
  color: $darkest100;
  outline: none;

  &::placeholder {
    color: $darkest40;
  }

  &:focus {
    border-color: $primary100;
  }
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}
