@import '../../../../index.scss';

.subTitle {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: $darkest100;
  cursor: pointer;
  display: flex;
  gap: 4px;
}
