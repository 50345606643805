@import '../../index.scss';

.Checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckboxInput {
  opacity: 0;
  position: absolute;

  &:checked + .CheckboxMarkIcon .mark {
    display: block;
  }
  &:checked + .CheckboxMarkIcon {
    background-color: $primary100;
  }
  &:disabled + .CheckboxMarkIcon {
    opacity: 0.5;
  }
}

.CheckboxMarkIcon {
  background-color: rgb(255, 255, 255);
  position: relative;
  border: 1px solid $primary100;
  padding: 2px;

  &[data-radio='true'] {
    border-radius: 50%;
  }

  &__default {
    width: 18px;
    height: 18px;
    border-radius: 7px;
    margin-right: 8px;
  }

  &__small {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 6px;
  }

  &__big {
    width: 24px;
    height: 24px;
    border-radius: 7px;
    margin-right: 8px;
  }
}
