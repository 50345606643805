@import '../../../../index.scss';

body {
  background-color: #f9f4ef;
}

.offersWrap {
  padding-bottom: 100px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 24px 25px;
}

.btnWrap {
  position: fixed;
  bottom: 0px;
  padding: 0px 25px;
  padding-top: 16px;
  padding-bottom: 36px;
  width: 100%;
  box-sizing: border-box;
  background-color: $primary10;
}
