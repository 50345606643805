@import '../../../index.scss';

.universityPageContainer {
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto 64px auto;
  padding: 0 24px;
  width: 100%;
  font-family: $fontFamilyInter;
  @media (max-width: 768px) {
    padding: 0 12px;
  }
}

.universityHeader {
  padding: 32px 0;
}

.wrapperLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
