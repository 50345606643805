@import '../../../../index.scss';

.wrapCard {
  border-radius: 10px;
  padding: 15px 20px;
  background-color: $white;
}

.wrapBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapFilter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.wrapFilter.closed {
  border-bottom: 1px solid $darkest20;
}

.extendedFilterTitle {
  display: flex;
  align-items: center;
}

.extendedFilterTitle span {
  margin-right: 10px;
}

.wrapExtendedFilterBlock:first-child {
  margin-right: 10px;
}

.clearButton {
  color: $darkest40;
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.clearButton:hover {
  color: $darkest60;
}

.showMore {
  font-size: 14px;
  font-weight: 700;
  font-family: $fontFamilyInter;
  margin-top: 15px;
  cursor: pointer;
  color: $primary100;
}

.filterBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  border-radius: 11px;
  padding: 15px 20px;
}

.icon {
  width: 24px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
  font-family: calibri;
}

.dropdownTitleWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;

  .dropdownTextWrap {
    display: flex;
    flex-direction: column;

    .dropdownName {
      font-size: 14px;
      font-weight: 400;
      color: $darkest100;
    }

    .dropdownCode {
      line-height: 14px;
      font-size: 10px;
      font-weight: 400;
      color: $darkest40;
    }
  }
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.wrapDropdownBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $primary15;
  padding: 12px 6px;
}

.filterCount {
  margin-right: 4px;
  min-width: 10px;
  border-radius: 4px !important;
}

.checkboxBlock {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}
