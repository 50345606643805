@import '../../index.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 25px;
  background: $primary15;
  margin-bottom: 21px;
}

.profilePageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 21px;
  padding: 0 16px;
  padding-bottom: 34px;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  padding: 16px;
  background: $primary15;

  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cardFooter {
    display: flex;
    align-items: center;
    width: 100%;
    &:has(:only-child) {
      justify-content: flex-end;
    }
    &:has(:nth-child(2)) {
      justify-content: space-between;
    }
  }
}

.inputGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .popupModalWrapper {
    padding: 0 12px;
    width: 100%;
    max-width: 400px;

    .popupModal {
      border-radius: 16px;
      background: $white;
      padding: 16px;
      gap: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .popupModalButtons {
        width: 100%;
        display: flex;
        gap: 4px;
        > * {
          flex: 1 1 auto;
          width: 100%;
        }
      }
    }
  }
}
