@import '../../index.scss';

.breadcrumbContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 0.875rem;
  font-family: $fontFamilyInter;
  font-style: normal;
  line-height: 140%;
}

.breadcrumbLink {
  font-weight: 400;
  color: $darkest80 !important;
  text-decoration: none !important;
  font-family: $fontFamilyInter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: color 0.2s ease;
  &:hover {
    color: $primary100 !important;
  }
  &:last-child {
    margin-right: 0;
  }
}

.breadcrumbSeparator {
  color: $darkest40;
  margin: 0 8px;
  display: flex;
  align-items: center;
}
