@import '../../../../../../index.scss';

.contactsContainer {
  padding: 24px 8px;
  font-family: $fontFamilyInter;

  @media (min-width: 768px) {
    padding: 24px;
  }
}

.contactSection {
  margin-bottom: 20px;
}

.contactValue {
  font-size: 16px;
  color: $darkest100;
  font-weight: 400;

  a {
    color: $primary100;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.admissionsSection {
  margin-top: 32px;
}

.contactPersonRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.contactPersonRole {
  font-size: 14px;
  color: $darkest60;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    width: 200px;
    margin-bottom: 0;
  }
}

.contactPersonName {
  font-size: 16px;
  color: $darkest100;

  a {
    color: $primary100;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Add horizontal line
.contactPersonRow:not(:last-child) {
  padding-bottom: 16px;
}
