@import '../../../index.scss';

.card {
  display: flex;
  gap: 40px;
  width: 100%;
  margin: 0 40px;
  align-items: center;
  font-family: $fontFamilyInter;
}

.imageContainer {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 25%;
  max-width: 600px;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  flex: 1 0 35%;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0 0 8px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
}

.description {
  line-height: 1.5;
  font-size: 16px;
  color: $darkest80;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.publishDate {
  font-size: 10px;
  color: $darkest60;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 4px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 4px 0;
  gap: 10px;
  width: fit-content;

  svg {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1200px) {
  .imageContainer {
    width: 45%;
    padding-bottom: 25%;
  }
}

@media (max-width: 960px) {
  .imageContainer {
    width: 50%;
    padding-bottom: 30%;
  }
}

@media (max-width: 768px) {
  .card {
    gap: 20px;

    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
    padding-bottom: 45%;
  }

  .title {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .description {
    -webkit-line-clamp: 6;
    line-clamp: 6;
  }
}
