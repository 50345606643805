@import '../../index.scss';

$button-default-padding: 18px 48px;
$button-slim-padding: 8px 16px;

$button-default-border-radius: 16px;
$button-slim-border-radius: 8px;

$button-default-icon-size: 28px;
$button-slim-icon-size: 18px;

$button-gap: 8px;
$button-transition: opacity 0.1s ease-in-out, transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75);

@mixin button-styles($bg-color, $text-color) {
  background-color: $bg-color;
  span {
    color: $text-color;
  }
}

.ButtonV2 {
  flex: 0 0 auto;
  border: 1px solid $primary100;
  outline: none;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $button-gap;

  background-color: $primary100;
  padding: $button-default-padding;
  box-sizing: border-box;

  padding: $button-default-padding;
  border-radius: $button-default-border-radius;

  .icon {
    height: $button-default-icon-size;
    width: $button-default-icon-size;
  }

  cursor: pointer;
  user-select: none;

  transition: $button-transition;

  &.disabled {
    opacity: 0.5;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:hover {
    transform: scale(0.99);
  }

  &:active {
    transform: scale(0.95);
  }

  &.slim {
    padding: $button-slim-padding;
    border-radius: $button-slim-border-radius;

    .icon {
      height: $button-slim-icon-size;
      width: $button-slim-icon-size;
    }
  }

  &.error {
    @include button-styles($error-main, $white);
  }

  &__primary {
    @include button-styles($primary100, white);
  }

  &__outline {
    @include button-styles(white, $primary100);
  }

  &__text {
    @include button-styles(transparent, $primary100);
    padding: 6px 12px;
    border: none;
    flex: none;
    width: auto;
  }

  &__text.error {
    @include button-styles(transparent, $error-light);
  }
}
