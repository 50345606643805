@import '../../index';

.AppHeader {
  flex: 0 0 auto;

  display: block;
  width: 100%;
  height: 57px;

  background-color: $darkest5;
  border-bottom: 1px solid $darkest10;

  box-sizing: border-box;

  > div {
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

    justify-content: space-between;
    align-items: center;

    > a {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 16px 24px 16px 0;
      box-sizing: border-box;
    }
  }
}

.AppHeaderLight {
  background-color: #f9f4ef;
  border-bottom: 1px solid #fdebd6;
}

.AppHeaderBurgerButton {
  position: relative;
  width: 24px;
  height: 24px;

  padding: 16px 0 16px 24px;

  cursor: pointer;

  > span {
    position: absolute;
    width: 24px;
    height: 3px;
    border-radius: 3px;

    background-color: #000000;

    transition:
      transform 0.1s ease-out,
      opacity 0.1s ease-out;
    left: 24px;
    &:nth-child(1) {
      top: 6px + 16px;
    }
    &:nth-child(2) {
      top: 14.67px + 16px;
    }

    z-index: 5;
  }

  &.open {
    > span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translateY(-4px) rotate(-45deg);
      }
    }
  }

  &.right {
    > span {
      &:nth-child(1) {
        top: 6px + 16px;
      }
      &:nth-child(2) {
        top: 14.67px + 16px;
      }
    }
    &.open {
      > span {
        &:nth-child(1) {
          transform: translateY(-5px + 8.67px) rotate(-45deg);
        }
        &:nth-child(2) {
          transform: translateY(4px - 8.67px) rotate(45deg);
        }
      }
    }
  }

  > div.AppHeaderBurgerButton_Background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    background-color: rgba($primary100, 0.25);
    transition: all 0.1s ease-out;
    z-index: 3;
  }

  &:active {
    > div.AppHeaderBurgerButton_Background {
      opacity: 0;
    }
  }
}

.AppHeaderMenu {
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $darkest5;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  &.open {
    opacity: 1;
    > div > .AppHeaderMenuGroup {
      opacity: 1;
      transform: translateY(0);
    }
  }
  overflow: scroll;
  @include scrollbar;
  z-index: 100500;

  > div {
    display: flex;
    margin: 0 auto;

    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;

    > .AppHeaderMenuGroup {
      background-color: $white;
      border-radius: 12px;
      // overflow: hidden;

      transition:
        opacity 0.1s ease-in-out,
        transform 0.1s ease-in-out;
      &:nth-child(2) {
        transition-delay: 0.025s;
      }
      &:nth-child(3) {
        transition-delay: 0.05s;
      }
      opacity: 0;
      transform: translateY(-16px);

      > .AppHeaderMenuButton {
        position: relative;
        overflow: hidden;
        text-decoration: none;
        color: $darkest100;

        display: flex;
        flex-direction: row;
        align-items: center;
        > .AppHeaderMenuButton_Box {
          width: 30px;
          padding: 15px;
          display: flex;
          justify-content: center;
          > .AppHeaderMenuButton_Emoji {
            flex: 0 0 auto;
            max-width: 30px;
            height: auto;
          }
        }
        > span.AppHeaderMenuButton_Name {
          flex: 1;
          padding: 15px 15px 15px 0;
          @include p1;
          font-size: 18px;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        cursor: pointer;
        user-select: none;

        min-height: 60px;

        transition: background-color 0.075s ease-in-out;
        &:hover {
          background-color: rgba($primary60, 0.1);
        }
      }
      > .AppHeaderMenuGroup_Separator {
        display: block;
        width: 100%;
        height: 1px;
        padding: 0 17px;
        box-sizing: border-box;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: $primary15;
        }
      }

      margin-bottom: 12px;
    }
  }

  &.ShareMenu {
    top: unset;
    transform: translateY(100%);
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    transition: 0.5s;
    &.open {
      transform: translateY(0%);
    }
    > div {
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      & .HeaderMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      & .AppHeaderMenuButton_Emoji {
        max-width: 20px !important;
      }

      & .AppHeaderMenuGroup {
        margin-bottom: 0;
      }
    }
  }
}

.AppHeaderMenuLight {
  background-color: #f9f4ef;

  > div {
    max-width: 800px;
    height: 100%;
  }
}

@keyframes AppHeaderMenuGroupFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-32px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.AuthContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;

  .LoginButton {
    background-color: white;
    color: $primary100 !important;
    font-size: 14px !important;
    line-height: 19.6px !important;
    font-weight: 400 !important;
    font-family: $fontFamilyInter !important;
    border: 1px solid $primary100 !important;
  }

  .RegisterButton {
    font-size: 14px !important;
    line-height: 19.6px !important;
    font-weight: 400 !important;
    font-family: $fontFamilyInter !important;
  }
}

.Annotations {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16.8px;
  font-weight: 400;
  font-family: $fontFamilyInter;
  gap: 3.5px;
  padding-bottom: 10px;

  .HeaderLink {
    color: $primary100;
    font-weight: 700;

    span {
      color: $primary100;
      font-weight: 700;
    }
  }
}
