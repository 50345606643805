@import '../../index.scss';
$link-default-padding: 18px 48px;
$link-slim-padding: 8px 16px;

$link-default-border-radius: 16px;
$link-slim-border-radius: 8px;

$link-default-icon-size: 28px;
$link-slim-icon-size: 18px;

$link-gap: 8px;
$link-transition: opacity 0.1s ease-in-out, transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75);

@mixin link-styles($bg-color, $text-color) {
  background-color: $bg-color;
  span {
    color: $text-color;
  }
}

.Link {
  flex: 0 0 auto;
  border: 1px solid $primary100;
  outline: none;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: $link-gap;

  background-color: $primary100;
  padding: $link-default-padding;
  box-sizing: border-box;

  cursor: pointer;
  user-select: none;

  transition: $link-transition;

  &.disabled {
    opacity: 0.5;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:hover {
    transform: scale(0.99);
  }

  &:active {
    transform: scale(0.95);
  }

  &__slim-size {
    padding: $link-slim-padding;
    border-radius: $link-slim-border-radius;

    .icon {
      height: $link-slim-icon-size;
      width: $link-slim-icon-size;
    }
  }

  &__default-size {
    padding: $link-default-padding;
    border-radius: $link-default-border-radius;

    .icon {
      height: $link-default-icon-size;
      width: $link-default-icon-size;
    }
  }

  &__button {
    @include link-styles($primary100, white);
    text-decoration: none;
  }

  &__button-outline {
    @include link-styles(white, $primary100);
    text-decoration: none;
  }

  &__text {
    @include link-styles(transparent, $primary100);
    padding: 6px 12px;
    border: none;
    flex: none;
    width: auto;
  }
}
