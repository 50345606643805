@import '../../../../index.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  background-color: $primary20;
  padding: 20px 25px;
  gap: 8px;
  font-family: $fontFamilyInter;

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .goBackButton {
      color: $primary100;
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      cursor: pointer;
    }
  }

  .title {
    font-size: 20px;
    line-height: 24.4px;
    color: $darkest100;
    font-weight: 700;

    span:first-of-type {
      margin-right: 4px;
    }
  }

  .description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

    .item {
      color: $darkest80;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }

    .dot {
      color: $primary40;
    }
  }
}

.cardWrap {
  display: flex;
  flex-direction: column;
  font-family: $fontFamilyInter;
  padding: 0 25px;
  gap: 16px;

  .title {
    font-family: $fontFamilyMariupol;
    font-weight: 700;
    font-size: 18px;
    line-height: 20.88px;
    color: $darkest100;
  }

  .card {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 24px;
    border-radius: 16px;
    gap: 24px;

    .propertyWrap {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .propertyTitle {
        font-family: $fontFamilyRoboto;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        color: $darkest60;
        text-transform: uppercase;
        letter-spacing: 5%;
      }

      .propertyValue {
        display: flex;
        flex-wrap: wrap;
        font-family: $fontFamilyInter;
        font-weight: 500;
        font-size: 16px;
        line-height: 23.2px;
        color: $darkest100;
      }

      .propertyLink {
        display: flex;
        flex-wrap: wrap;
        font-family: $fontFamilyInter;
        font-weight: 500;
        font-size: 16px;
        line-height: 23.2px;
        color: $primary100;
      }

      .noBreak {
        white-space: nowrap;
      }
    }

    .educationProgramsTable {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .educationalProgram {
      font-family: $fontFamilyInter;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      color: $darkest100;
      padding: 8px 0px;
      border-bottom: 1px solid $darkest5;
    }

    .educationalProgram:last-of-type {
      border-bottom: none;
    }

    .bold {
      font-weight: 700;
    }

    .withBorder > td {
      border-bottom: 1px solid $darkest5;
    }

    .withoutBorder > td {
      border-bottom: none !important;
    }

    .table {
      border-collapse: collapse;
      thead {
        font-family: $fontFamilyInter;
        font-size: 14px;
        line-height: 19.6px;
        color: $primary100;
        font-weight: 600;
        text-align: left;
        border-bottom: 1px solid $darkest5;
        width: 100%;
        th {
          padding: 12px 0;
        }
      }

      tbody {
        font-family: $fontFamilyRoboto;
        font-size: 14px;
        line-height: 16.41px;
        color: $darkest100;
        td {
          padding: 12px 0;
          border-bottom: 1px solid $darkest5;
        }

        tr:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.studentCard {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px;
  gap: 20px;
  background-color: white;

  .studentTitleWrap {
    display: flex;
    justify-content: center;

    .telegram {
      height: 40px;
      width: 40px;
    }

    .avatar {
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      object-fit: cover;

      stroke {
        fill: $primary100;
      }
    }

    .studentTitle {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 8px;
      padding: 0 18px;
      flex-grow: 1;

      .description {
        .item {
          display: inline;
          color: $darkest80;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
        }

        .dot {
          display: inline;
          color: $darkest60;
          margin: 0 5px;
        }
      }
    }
  }
  .quoteWrap {
    .quoteImage {
      display: inline;
      margin-right: 10px;
    }

    .quote {
      display: inline;
      font-family: $fontFamilyRoboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      color: $darkest60;
    }
  }
}

.noWrap {
  text-wrap: nowrap;
}
