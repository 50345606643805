@import '../../index.scss';

.container {
  font-family: $fontFamilyMariupol;
  max-width: 1350px;
  margin: 24px auto;
  padding: 0 12px;
  width: 100%;
}

.containerRight {
  padding-right: 12px;
  margin-right: 12px;
}

.title {
  font-family: $fontFamilyMariupol;
  font-size: 40px !important;
  margin-bottom: 16px;
  max-width: 610px;
}

.textWrapper {
  font-family: $fontFamilyInter;
  display: flex;
  flex-direction: column;
  max-width: 730px;
  margin-bottom: 24px;
  p {
    font-weight: 500;
  }
}

.list {
  li::marker {
    color: $primary100;
  }
  span {
    font-weight: 700;
    color: $darkest100;
  }
  p {
    font-weight: 500;
  }
}
