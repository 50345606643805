@import '../../index.scss';

@mixin centered($direction: row) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

/* Layout styles */
.mobile {
  display: block !important;

  .card {
    margin-bottom: 0px;
    padding-bottom: 100px;
  }
}

.desktop {
  display: none !important;
}


a {
  color: $primary100;
  font-weight: 700;
}

@include respond-to(sm) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: block !important;
  }
}

.loginPageWrapper {
  @include centered();
  height: 100dvh;
  background-image: url('../../../public/images/auth/login-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 0 14px;
}

.registerPageWrapper {
  @include centered(column);
  height: 100dvh;
  background-repeat: no-repeat;
  background-size: cover;

  .card {
    border-radius: 45px 45px 0 0;
    background-color: $primary10;
    max-width: map-get($breakpoints, md);
  }
}

.registerBackground {
  background-image: url('../../../public/images/auth/register-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  flex-grow: 1;
  @include centered();
}

.card {
  @include centered(column);
  box-sizing: border-box;
  gap: 8px;
  width: 100%;
  border-radius: 45px;
  background-color: $primary15;
  padding: 40px 36px;
  max-width: map-get($breakpoints, sm);
  position: relative;
  margin-bottom: 100px;

  @media screen and (max-width: 375px) {
    padding: 40px 20px;
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 4px;
    font-size: min(calc(1.5vw + 1.5rem), 40px);
  }
  button {
    margin: 24px 0;
  }
  .secondaryText {
    text-align: center;
    color: $darkest60;
  }
  @media screen and (max-width: 375px) {
    button {
      padding: 16px 24px;
    }
  }
  a,
  a p {
    text-decoration: none;
    color: $primary100;
    font-weight: bold;
  }
  box-shadow:
    0 0 27.52px 0 #ffc685,
    0 0 15.72px 0 #ffc685,
    0 0 9.17px 0 #ffc685,
    0 0 4.59px 0 #ffc685,
    0 0 1.31px 0 #ffc685,
    0 0 0.66px 0 #ffc685;
}

/* Component styles */

// AbitlyLogo
.abitly-logo {
  cursor: pointer;
}

// ValidationError
.error {
  color: red !important;
}

// Label
.required {
  color: $darkest40;
}

// Section
.verticalSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.horizontalSection {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

// ChipsInput
.chipsWrapper {
  width: 100%;
  padding-bottom: 10px;
  overflow-x: scroll;

  @include scrollbar;

  .chipsInput {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;
    min-width: 380px;
  }
}

// RegisterBase
.regiterBaseWrap {
  @include centered(column);
  box-sizing: border-box;
  padding: 20px 16px;
  gap: 20px;

  .registerBaseTopBar {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .registerBaseContent {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    & > form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.mobileBar {
  display: flex !important;
}
.desktopBar {
  display: none !important;
}

@include respond-to(sm) {
  .mobileBar {
    display: none !important;
  }
  .desktopBar {
    display: flex !important;
  }
}

@include respond-to(sm) {
  .regiterBaseWrap {
    height: 100dvh;
    background-image: url('../../../public/images/auth/login-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: flex-start;

    .registerBaseContent {
      margin-top: 90px;
      box-sizing: border-box;
      grid-column: 3 / 11;
      background-color: #f9f4ef;
      border-radius: 45px;
      box-shadow:
        0 0 27.52px 0 #ffc685,
        0 0 15.72px 0 #ffc685,
        0 0 9.17px 0 #ffc685,
        0 0 4.59px 0 #ffc685,
        0 0 1.31px 0 #ffc685,
        0 0 0.66px 0 #ffc685;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding: 36px;
      min-height: 55dvh;
      max-height: calc(100dvh - 180px);
      overflow: hidden;
      position: relative;
      .registerBaseTopBar {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > form {
        padding: 0 16px;
        width: 50%;
        max-height: 100%;
        @include scrollbar;
        overflow-y: scroll;
        min-width: 350px;
      }
    }
  }
}

// Step
.step {
  @include centered();
  gap: 10px;
  cursor: pointer;
  p {
    color: $primary100;
  }
}

.topLeftCorner {
  position: absolute;
  top: 24px;
  left: 24px;
}

// CrossButton
.topRightCorner {
  position: absolute;
  top: 24px;
  right: 24px;
}

.crossButton {
  @include centered();
  width: 36px;
  height: 36px;
  border: none;
  cursor: pointer;
}

// RegisterCompleted
.registerCompletedPageWrapper {
  @include centered(column);
  gap: 14px;

  .verticalSection {
    justify-content: center;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      color: $darkest60;
    }
  }
}

.heartImage {
  height: 60px;
  width: 60px;
  margin-top: 20px;
}

.abitlyImage {
  img {
    max-width: 206px;
    width: calc(100px + 10vw);
  }
}
