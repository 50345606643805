@import '../../../../index.scss';

.inputCheckBox {
  opacity: 0;
  position: absolute;
}

.checkboxBig {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  max-width: 18px;
  max-height: 18px;
  min-height: 18px;
  min-width: 18px;
  position: relative;
  border: 1px solid $primary100;
  border-radius: 7px;
  margin-right: 8px;
}

.checkboxBig[data-radio='true'] {
  border-radius: 50%;
}

.checkboxSmall {
  padding: 2px;
  background-color: rgb(255, 255, 255);
  max-width: 14px;
  max-height: 14px;
  min-height: 14px;
  min-width: 14px;
  position: relative;
  border: 1px solid $primary100;
  border-radius: 7px;
  margin-right: 6px;
}

.checkboxSmall[data-radio='true'] {
  border-radius: 50%;
}

.inputCheckBox:checked + .checkboxBig .mark {
  display: block;
}

.inputCheckBox:checked + .checkboxBig {
  background-color: $primary100;
}

.inputCheckBox:disabled + .checkboxBig {
  opacity: 0.5;
}

.inputCheckBox:checked + .checkboxSmall .mark {
  display: block;
}

.inputCheckBox:checked + .checkboxSmall {
  background-color: $primary100;
}

.inputCheckBox:disabled + .checkboxSmall {
  opacity: 0.5;
}

.description {
  color: $darkest40;
  font-size: 10px;
  font-family: $fontFamilyInter;
}

.titleWrap {
  display: flex;
  align-items: center;
}

.wrapWithoutDesc {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid $primary20;
}

.wrapWithDesc {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid $primary20;
}

.wrapCheckbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.title {
  font-family: $fontFamilyInter;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.title.disabled {
  color: $darkest60;
}
