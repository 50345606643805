@import '../../../../index.scss';

.wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  background-color: $primary15;
}

.wrapBlock:first-child {
  display: flex;
  align-items: center;
}

.wrapBlock .arrowLeftIcon {
  margin-right: 15px;
  height: 100%;
  cursor: pointer;
}
.arrowLeftIcon > path {
  stroke: $primary100;
  stroke-width: 3px;
}

.clearButton {
  color: $primary100;
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.clearButton:hover {
  color: $darkest60;
}

.title {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: $darkest100;
  @media screen and (max-width: 340px) {
    font-size: 25px;
  }
}
