@import '../../index.scss';

.ChipButton {
  @include p3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  width: max-content;
  background-color: white;
  border-radius: 10px;
  height: 20px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.1s ease-out;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  max-width: 100%;
  white-space: nowrap;
}

.ChipButton[data-selected='true'] {
  border-color: $primary100;
}
