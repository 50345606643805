@import '../../../../index.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  font-family: $fontFamilyInter;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}
.descWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 700px;
  margin-bottom: 12px;
  span {
    font-weight: 700;
  }
}

.formula {
  font-weight: 500;
  line-height: 150%;
  > p {
    margin-bottom: 4px;
  }
}

.subtitle {
  max-width: 850px;
  margin-bottom: 4px;
  span {
    font-weight: 700;
    color: $darkest100;
  }
}

.coefList {
  margin: 0 0 4px 0;
  line-height: 150%;
  li::marker {
    color: $primary100;
  }
  li span {
    font-weight: 700;
    color: $darkest100;
  }
}

.formulaSVG {
  display: flex;
  align-items: center;
  margin: 40px;
  gap: 12px;

  span {
    font-weight: 700;
    color: $darkest100;
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    margin: 12px 0px;
    gap: 4px;
  }
}
