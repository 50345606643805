@import '../../../../../../index.scss';

.structureContainer {
  padding: 24px 16px;
  font-family: $fontFamilyInter;

  @media (max-width: 768px) {
    padding: 24px 4px;
  }
}

.controlsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 600px) {
    align-items: start;

    flex-direction: column;
    gap: 12px;
  }
}

// Specialities section
.specialitiesContainer {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.specialityHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.specialityCode {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary40;
  color: $darkest100;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 6px;
}

.specialityName {
  font-size: 16px;
  font-weight: 600;
  color: $darkest100;
  margin: 0;
  padding: 4px 0;
}

// Faculties list
.facultiesList {
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.facultyItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.facultyIcon {
  display: flex;
  align-items: center;
  color: $darkest60;

  img {
    width: 16px;
    height: 16px;
  }
}

// Empty state
.emptyState {
  padding: 32px;
  text-align: center;
  color: $darkest60;
  font-size: 16px;
}
