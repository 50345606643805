@import '../../index.scss';

.footerContainer {
  background-color: #f9f4ef;
  width: 100%;
  margin: auto auto 0 auto;
  padding: 32px 24px 20px 24px;
  border-top: 1px solid $primary20;
  font-family: $fontFamilyInter;

  @media (max-width: 768px) {
    padding: 16px 24px 20px 24px;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.footerLinksWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 80px;

  @media (max-width: 1024px) {
    gap: 40px;
  }

  @media (max-width: 768px) {
    gap: 16px;
    flex-direction: column;
    width: 100%;
  }
}

.footerLogoSection {
  display: flex;
  flex-direction: column !important;
  gap: 16px;
  @media (max-width: 768px) {
    gap: 4px;
    align-items: center;
    text-align: center;
  }
}

.footerLogo {
  .logoText {
    font-weight: 700;
    color: $primary100;
    font-size: 24px;
  }
}

.footerTagline {
  color: $darkest80;
}

.footerLinks {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
  gap: 16px 24px;
  width: 100%;

  @include respond-to(lg) {
    .footerLinks {
      gap: 16px 80px;
    }
  }

  @include respond-to(md) {
    gap: 16px 40px;
  }

  @media (max-width: 768px) {
    align-self: baseline;
    gap: 6px;
  }

  .footerLinkItem {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $darkest80;
    text-decoration: none;
    font-weight: 700;
    line-height: 126%;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: $primary100;
    }

    img {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.footerSocial {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .socialLinks {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media (max-width: 768px) {
      gap: 0;
    }
  }

  .socialIcons {
    display: flex;
    gap: 8px;

    img {
      width: 32px;
      height: 32px;
      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .websiteLink {
    color: $primary100;
    font-weight: 500;
  }
}

.footerCopyright {
  margin-top: 30px;
  display: flex;
  flex-direction: column !important;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  p {
    color: $darkest80;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  }

  @media (max-width: 768px) {
    margin-top: 12px;
    padding-top: 0;
  }
}

.footerPolicyLinks {
  display: flex;
  gap: 6px;
  line-height: 140%;
  font-size: 12px;
  align-items: center;
  .policyLink {
    color: $primary100;
    transition: color 0.2s ease;
    font-weight: 700;

    &:hover {
      color: $primary100;
      text-decoration: underline;
    }
  }
}
