@import '../../index.scss';

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 64px;
}

@include respond-to(md) {
  .mainContainer {
    gap: 80px;
  }
}

.sectionWrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: map-get($breakpoints, xl);
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;

  @include respond-to(md) {
    padding: 0 24px;
    gap: 48px;
  }

  .headerSection {
    box-sizing: border-box;
    background-color: #fffbf7;
    border-radius: 40px;
    width: 100%;
    padding: 16px;
    position: relative;

    .headerSubSection {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 30px;
      border: 3px solid $primary100;
      width: 100%;
      height: 100%;
      padding: 40px 0px;
    }

    .hat1 {
      position: absolute;
      bottom: 0px;
      right: 2vw;
      width: min(calc(100px + 7.5vw), 185px);
    }
    .hat2 {
      display: none;
    }
    @include respond-to(md) {
      .hat2 {
        display: block;
        position: absolute;
        bottom: 0px;
        left: 15vw;
      }
    }
    .hat3 {
      position: absolute;
      top: -26px;
      left: 1vw;
    }
  }

  .abitlySection {
    gap: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    box-sizing: border-box;
    width: 100%;

    img {
      max-width: 500px;
      width: 100%;
    }

    .logoContainer {
      max-width: 168px;
    }

    @include respond-to(md) {
      .logoContainer {
        max-width: 216px;
      }
    }

    .note {
      padding: 24px;
      background-color: #fffbf7;
      border-radius: 21px;
      border-left: 2px solid $primary100;
    }
  }

  @include respond-to(md) {
    .abitlySection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .valuesSection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;

    .valueCard {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
      padding: 32px;
      border-radius: 30px;
      background-color: #fffbf7;

      .valueCardImage {
        width: 48px;
        height: 48px;
      }
    }
  }

  @include respond-to(md) {
    .valuesSection {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .motivationSection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    .motivationCard {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
      padding: 32px;
      border-radius: 30px;
      background-color: #fffbf7;
    }

    .motivationCard:nth-child(2n) {
      border: none;
      border-top: 2px solid $primary100;
      border-radius: 0 0 30px 30px;
    }
    .motivationCard:nth-child(2n + 1) {
      border: none;
      border-bottom: 2px solid $primary100;
      border-radius: 30px 30px 0 0;
    }
  }

  .footerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 40px 0;
    width: 100%;
    border-left: 3px solid $primary100;
    border-right: 3px solid $primary100;
    border-radius: 21px;
    background-color: #fffbf7;

    .heart {
      width: 31px;
      height: 31px;
    }
  }

  @include respond-to(md) {
    .motivationSection {
      grid-template-columns: repeat(2, 1fr);
    }
    .motivationCard:nth-child(2n) {
      border: none !important;
      border-left: 2px solid $primary100 !important;
      border-radius: 0 30px 30px 0 !important;
    }
    .motivationCard:nth-child(2n + 1) {
      border: none !important;
      border-right: 2px solid $primary100 !important;
      border-radius: 30px 0 0 30px !important;
    }
  }
}

.participantsGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;
  justify-items: center;

  @include respond-to(md) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 30px;
  }

  @include respond-to(lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 60px;
  }

  .participantCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    gap: 20px;
  }

  .participantImage {
    border-radius: 8px;
    aspect-ratio: 1;
    object-fit: cover;
    max-width: 100%;
  }
}
