@import '../../../../../../index.scss';

.structureContainer {
  padding: 24px 16px;
  font-family: $fontFamilyInter;

  @media (max-width: 768px) {
    padding: 24px 4px;
  }
}

.controlsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 600px) {
    align-items: start;

    flex-direction: column;
    gap: 12px;
  }
}

.listContainer {
  margin-top: 32px;
}

.container {
  margin-bottom: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.icon {
  display: flex;
  align-items: center;
  color: $darkest60;
}

.specialitiesList {
  list-style-type: none;
  padding: 0;
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.specialityItem {
  display: flex;
  align-items: center;
  gap: 12px;
}

.specialityId {
  font-size: 14px;
  line-height: 140%;
  font-family: $fontFamilyInter;
  background-color: $primary40;
  padding: 2px 4px;
  border-radius: 6px;
  text-align: center;
}
