@import '../../../../../index.scss';
.sortButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid $darkest20;
  border-radius: 8px;
  background: white;
  font-family: $fontFamilyInter;
  font-size: 14px;
  color: $darkest80;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 7px 12px;
  justify-content: center;
  gap: 6px;

  &:hover {
    border-color: $primary100;
    color: $primary100;
  }

  &.active {
    background-color: $primary10;
    border-color: $primary100;
    color: $primary100;

    .sortIcon {
      transform: rotate(180deg);
    }
  }
}

.sortIcon {
  display: flex;
  align-items: center;
  color: $darkest60;
}
