@import '../../../../../../index.scss';

.competitiveContainer {
  padding: 24px 0;
  font-family: $fontFamilyInter;
}

.offersGridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: 1024px) {
    gap: 12px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.offersGrid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
}

.offerCard {
  background-color: $white;
  border-radius: $border-radius-md;
  box-shadow: $shadow-sm;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.universityHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-bottom: 1px solid $darkest10;
}

.universityInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.universityIcon {
  display: flex;
  align-items: center;
  color: $darkest60;
  font-size: 20px;
}

.universityName {
  font-size: 14px;
  font-weight: 500;
  color: $darkest100;
}

.universityActions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bookmarkButton,
.expandButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: $darkest60;
  cursor: pointer;
  padding: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: $primary100;
  }
}

.expandButton {
  transform: rotate(0);
  transition: transform 0.3s ease;

  &.collapsed {
    transform: rotate(180deg);
  }
}

// Content that can be hidden
.offerContent {
  max-height: 1000px;
  opacity: 1;
  transition:
    max-height 0.5s ease,
    opacity 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;

  &.hidden {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }
}
// Faculty Info
.facultyInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: $white;
}

.facultyIcon {
  display: flex;
  align-items: center;
  color: $darkest60;
  font-size: 20px;
}

// Specialty Info
.specialtyInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px 12px 16px;
}

.specialtyCode {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  background-color: $primary40;
  color: $darkest100;
  font-size: 14px;
  line-height: 140%;
  border-radius: $border-radius-sm;
}

// Scores Container
.scoresContainer {
  display: flex;
  background-color: $primary10;
}

.scoreColumn {
  padding: 16px;
  flex: 1;

  &:first-child {
    border-right: 1px solid rgba($darkest20, 0.4);
  }
}

// Score Headers and Values
.header {
  font-size: 13px;
  color: $darkest80;
  margin-bottom: 4px;
}

.value {
  font-size: 16px;
  font-weight: 600;
  color: $darkest100;
  margin-bottom: 12px;
  font-family: $fontFamilyMariupol;
}

// Action Buttons
.actionButtons {
  display: flex;
  padding: 16px;
  gap: 12px;
}

.applyButton {
  flex: 1;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid $primary100;
  border-radius: $border-radius-md;
  color: $primary100;
  font-family: $fontFamilyInter;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: rgba($primary100, 0.1);
  }
}

.detailsButton {
  text-decoration: none;
  flex: 1;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: transparent;
  border: 1px solid $primary100;
  border-radius: $border-radius-md;
  color: $primary100;
  font-family: $fontFamilyInter;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba($primary100, 0.1);
  }

  svg {
    stroke: $primary100;
  }

  @media (max-width: 767px) {
    flex: 0;
    gap: 4px;
    padding: 8px 6px 8px 12px;
  }
}

.loadMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.loadMoreButton {
  padding: 12px 24px;
  background-color: transparent;
  border: 1px solid $primary100;
  border-radius: $border-radius-md;
  color: $primary100;
  font-family: $fontFamilyInter;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: rgba($primary100, 0.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.loading {
  padding: 32px;
  text-align: center;
  color: $darkest60;
  font-size: 16px;
}

.noData {
  padding: 32px;
  text-align: center;
  color: $darkest60;
  font-size: 16px;
}
