@import '../../../../index.scss';

.AppOffersButton {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: $primary100;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  background-color: #fff;
  padding: 0px 16px;
  gap: 8px;
  transition: background-color 0.075s ease-in-out;
  border: 1px solid $primary40;

  > .AppOffersButton_Box {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    > .AppOffersButton_Emoji {
      flex: 0 0 auto;
      max-width: 30px;
      height: auto;
      svg {
        stroke: $primary40;
      }
    }
  }
  > span.AppOffersButton_Name {
    padding: 8px 0;
    @include p1;
    font-size: 14px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &:hover {
    background-color: rgba($primary60, 0.1);
  }
}
