@import '../../index.scss';

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 64px;
}

@include respond-to(md) {
  .mainContainer {
    gap: 80px;
  }
}

.sectionWrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: map-get($breakpoints, xl);
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;

  @include respond-to(md) {
    padding: 0 24px;
    gap: 48px;
  }

  .headerSection {
    box-sizing: border-box;
    background-color: #fffbf7;
    border-radius: 40px;
    width: 100%;
    padding: 16px;
    position: relative;

    .headerSubSection {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 30px;
      border: 3px solid $primary100;
      width: 100%;
      height: 100%;
      padding: 40px 0px;
    }
  }

  .abitlySection {
    gap: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

    img {
      max-width: 500px;
      width: 100%;
    }

    .logoContainer {
      max-width: 168px;
    }

    @include respond-to(md) {
      .logoContainer {
        max-width: 216px;
      }
    }
  }

  @include respond-to(md) {
    .abitlySection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .partnerCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    .partnerCard {
      background-color: #fffbf7;
      border-radius: 8px;
      padding: 8px;
      max-width: min(250px, calc(75px + 10vw));
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 65px;
        max-width: 100%;
        mix-blend-mode: multiply;
      }
    }
  }
  @include respond-to(md) {
    .partnerCards {
      justify-content: flex-start;
    }
  }

  .becomePartnerSection {
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    background-color: $primary20;
    padding: 48px 32px;

    .becomePartnerButton {
      width: max-content;
      margin-top: 16px;
    }
  }

  @include respond-to(md) {
    .becomePartnerSection {
      padding: 64px;
    }
  }
}
