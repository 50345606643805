@import '../../../../index.scss';

.gradesGraphWrapper {
  display: flex;
  margin-bottom: 24px;
  font-family: $fontFamilyInter;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.textWrapper {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: $fontFamilyInter;
  font-weight: 500;
  margin-bottom: 12px;
  h2 {
    font-family: $fontFamilyMariupol;
    font-size: 30px;
    margin-top: 12px;
  }
}
.h2 {
  font-family: $fontFamilyMariupol;
  font-size: 30px !important;
  margin-bottom: 12px;
}
.h3 {
  font-family: $fontFamilyMariupol;
  font-size: 24px !important;
  margin-bottom: 12px;
  margin-top: 24px;
}
.subtitle {
  max-width: 730px;
  font-family: $fontFamilyInter;
  margin-bottom: 12px;
  font-weight: 500;
  span {
    font-weight: 700;
    color: $darkest100;
  }
}
.calcDescriptionWrapper {
  font-family: $fontFamilyInter;
  font-weight: 500;
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 16px;
    li {
      margin-bottom: 12px;
    }
  }
}
