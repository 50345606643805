@import '../../../../index.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.wrapperInputs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.inputWrapper {
  display: flex;
  max-width: 354px;
  gap: 12px;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    min-width: 11%;
  }
  > div {
    margin-bottom: 0 !important;
    background-color: white !important;
    > div {
      background-color: white !important;
    }
  }
  margin-bottom: 16px;
}

.subjectInputs {
  display: flex;
  flex-direction: column;

  @media (max-width: 612px) {
    max-width: none;
    width: 100%;
  }
}

.calcButton {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  button {
    width: 100%;
    max-width: 382px;
  }
}
.specialityDropdown {
  width: 100%;
  max-width: 350px;
  margin-bottom: 0px;

  @media (max-width: 612px) {
    max-width: none;
    width: 100%;
  }
}
.additionalGradesInputs {
  width: 100%;
  margin-bottom: 32px;

  > p {
    margin-bottom: 16px;
  }

  @media (max-width: 612px) {
    max-width: none;
    width: 100%;
  }
}

.optionalSubjectsWrapper {
  @media (max-width: 612px) {
    width: 100%;
  }
}

.optionalSubjects {
  display: grid;
  padding-top: 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  column-gap: 16px;

  @media (max-width: 612px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
