@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import '../../font/include.css';
@import '../../index.scss';

.App {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 100%;

  height: 100%;
  min-height: 100dvh;
  background-color: #f9f4ef;

  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }
}
